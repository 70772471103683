import * as React from 'react';
import { Grid, Button, MenuList, Paper, MenuItem, Popper, ClickAwayListener, Toolbar, AppBar, Typography, Box, Divider, List, ListItem, ListItemButton, ListItemText, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ServiceEnum, ServiceID } from '../util/Services';
import { useMatch } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ThemeModeButton from './ThemeModeButton';
import AppointmentButton from './AppointmentButton';

interface LinkBarPropTypes {
  copyright?: React.ReactNode;
  home?: boolean;
  elevation?: number;
  mode: string;
  toggleColorMode: () => void;
}
const LinkBar: React.FC<LinkBarPropTypes> = (props: LinkBarPropTypes) => {
  const { home, elevation, copyright, mode, toggleColorMode } = props;
  const nav = useNavigate();
  const currentRoute = useLocation();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [serviceMenuOpen, setServiceMenuOpen] = useState<boolean>(false);
  const serviceAnchorEl = useRef<HTMLButtonElement | null>(null);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const handleServiceMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    function isTouchDevice() {
      return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }
    if (isTouchDevice()) {
      nav('/services');
      setServiceMenuOpen(true);
    } else {
      nav('/services');
      setServiceMenuOpen(false);
    }
  };
  const handleServiceMenuHover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setServiceMenuOpen(true);
  };
  const closeServiceMenu = () => {
    setServiceMenuOpen(false);
  };
  const attemptServiceNavAndScroll = (enumeration: ServiceEnum) => {
    setServiceMenuOpen(false);
    navScroll(enumeration);
  };
  const navScroll = (e?: ServiceEnum) => {
    const useSmooth = '/services' === currentRoute.pathname;
    if('/services' !== currentRoute.pathname) {
      nav('/services');
    }
    setTimeout(() => {
      if (e) {
        const eN = e as ServiceEnum;
        let elem;
        switch (eN) {
          case ServiceEnum.infant:
            elem = document.getElementById(ServiceID.infant);
            break;
          case ServiceEnum.children:
            elem = document.getElementById(ServiceID.children);
            break;
          case ServiceEnum.adolescent:
            elem = document.getElementById(ServiceID.adolescent);
            break;
          case ServiceEnum.adult:
            elem = document.getElementById(ServiceID.adult);
            break;
          default:
            break;
        }
        if (elem) {
          elem.scrollIntoView({behavior: useSmooth ? "smooth" : "auto", block: "start", inline: "nearest"});
        }
      }
    }, 1);
  };

  const servicesPopper = <Popper
      open={serviceMenuOpen}
      key={'popper'}
      anchorEl={serviceAnchorEl.current}
      placement="bottom-start"
      disablePortal
      style={{ zIndex: 3000 }}
    >
      <ClickAwayListener onClickAway={closeServiceMenu}>
        <Paper>
          <MenuList id="service-menu">
            <MenuItem onClick={() => attemptServiceNavAndScroll(ServiceEnum.infant)}>Infant/Young Child & Parent</MenuItem>
            <MenuItem onClick={() => attemptServiceNavAndScroll(ServiceEnum.children)}>Children</MenuItem>
            <MenuItem onClick={() => attemptServiceNavAndScroll(ServiceEnum.adolescent)}>Adolescents</MenuItem>
            <MenuItem onClick={() => attemptServiceNavAndScroll(ServiceEnum.adult)}>Adults</MenuItem>
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>;

  const homeButton = <Button key={1} className={`router-button ${useMatch("/home") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/home'}>Home</Button>;
  const coreNavButtons = [
    <Button key={2} className={`router-button ${useMatch("/about") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/about'}>About Me</Button>,
    <Button
      key={3} 
      className={`router-button ${useMatch("/services") ? "active-nav" : ""}`}
      //ref={serviceAnchorEl}
      variant={'text'}
      onClick={handleServiceMenuClick}
      // onMouseOver={handleServiceMenuHover}
      // aria-controls={serviceMenuOpen ? 'service-menu' : undefined}
      // aria-expanded={serviceMenuOpen ? 'true' : undefined}
      // aria-owns={serviceMenuOpen ? 'service-menu' : undefined}
      // aria-haspopup={'true'}
    >
      Services
    </Button>,
    <Button key={4} className={`router-button ${useMatch("/fees") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/fees'}>Insurance & Fees</Button>,
    <Button key={5} className={`router-button ${useMatch("/professional") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/professional'}>Professional</Button>,
    <Button key={6} className={`router-button ${useMatch("/contact") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/contact'}>Contact</Button>
    //<ThemeModeButton key={7} mode={mode} toggleColorMode={toggleColorMode} />
  ];
  const navButtons = home ? coreNavButtons : [homeButton, ...coreNavButtons];
  const drawerWidth = 240;
  const container = window !== undefined ? () => document.body : undefined;
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className={'nav-drawer'} >
      <Grid container flexDirection={'column'} padding={'48px 0px'}>
        {navButtons}
      </Grid>
    </Box>
  );
  return (
    <AppBar className={'top-nav'} position={home ? "relative" : "sticky"} color={'default'} elevation={2} sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', height: 48 }}>
      <Toolbar variant="dense" sx={{ marginLeft: isSmOrLess ? 0 : 'auto', marginRight: isSmOrLess ? 0 : 'auto' }}>
        {!isSmOrLess && navButtons}
        {isSmOrLess && (
          <Box sx={{ width: '100%' }} >
            <AppointmentButton />
          </Box>
        )}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: drawerWidth, backgroundColor: 'transparent' }} elevation={0}>
            {copyright}
          </Paper>
        </Drawer>
      </nav>
    </AppBar>
  );
}

export default LinkBar;