import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes, BrowserRouter as Router, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createModeTheme } from './theme';
import { Grid, useTheme } from '@mui/material';
import Home from './pages/Home';
import Contact from './pages/Contact';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVerification, selectVerification } from './util/Slice';
import { useEffect } from 'react';
import "./App.scss"
import LinkBar from './components/LinkBar';
import Copyright from './components/Copyright';

const App: React.FC = () => {
  const verifyStyleSelect = useSelector(selectVerification);
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );
  const obtainVerifyStyling = () => {
    let verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
    if (verifyButton && !verifyStyleSelect) {
      let css = verifyButton.getAttribute('style');
      if (css) {
        dispatch(fetchVerification(css));
      } else {
        var observer = new MutationObserver(function(mutations) {
          observer.disconnect(); 
          verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
          const css = verifyButton.getAttribute('style');
          if (css) {
            dispatch(fetchVerification(css));
          }
        });
        observer.observe(verifyButton, { attributeFilter: ['style'] });
      }
    }
  }
  useEffect(() => {
    obtainVerifyStyling();
    let verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
    if (verifyButton && verifyStyleSelect) {
      verifyButton.style.display = 'none';
    }
  }, [verifyStyleSelect]); 
  useEffect(() => {
    obtainVerifyStyling();
  }, []);
  const updatedTheme = createModeTheme(mode);
  function ContentInRouter() {
    return (
      <Grid item container justifyContent={'center'}  height={"100%"} width={"100%"}>
        <Grid item container minHeight={'100%'} height={'fit-content'} paddingBottom={isSmOrLess ? '48px' : 0}>
          <LinkBar home={false} mode={theme.palette.mode} toggleColorMode={colorMode.toggleColorMode} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Grid>
        <Copyright/>
      </Grid>
    );
  }
  return ( 
    <ThemeProvider theme={updatedTheme}>
    <CssBaseline />
      <Router>
        <Grid container alignContent={"space-between"} justifyContent={'center'} height={"100%"} width={"100%"}>
          <ContentInRouter />
        </Grid>
      </Router>
    </ThemeProvider>
  );
}

export default App;