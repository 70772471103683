import { Grid, Typography, Link, useTheme, useMediaQuery } from "@mui/material";
import React from "react";

const Copyright: React.FC = () => {
    const theme = useTheme();
    const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid item height={"fit-content"} width={"100%"} alignContent={'center'} padding={'8px 0px 8px 0px'} sx={{ background: '#d8dbc8' }}>
            <Typography variant={"body2"} sx={{ fontSize: isSmOrLess ? '11px' : '' }} color={isSmOrLess ? "white" : "text.secondary"} align="center">
                {'Copyright © '}
                <Link underline={'hover'} color="inherit" href="http://angelacontento.com/">
                Angela Contento Therapy
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Grid>
    );
};

export default Copyright;
