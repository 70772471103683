import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Card, CardMedia } from '@mui/material';
import Ac from '../media/ac_headshot.jpeg';

const Info: React.FC = () => {
    const theme = useTheme();
    const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid item container maxWidth={'1000px'} minHeight={'300px'} justifyContent={'center'} padding={'48px 24px 16px 48px'}>
            <Grid item container justifyContent={'center'} padding={1}>
                <Grid item container justifyContent={'center'} md={8}>
                    <Card component={Grid} minHeight={'300px'} maxWidth={"200px"} width={"100%"} elevation={2} sx={{ borderRadius: 2 }}> 
                        <CardMedia sx={{ width: "100%", height: "100%" }} image={Ac} />
                    </Card>
                    <Grid item padding={isSmOrLess ? '8px 0px' : '8px 16px'}>
                        <Typography align={'left'} fontWeight={400} variant={isSmOrLess ? 'h4' : 'h3'} sx={{ fontFamily: 'Lora, serif'}}>Angela Contento</Typography>
                        <Typography align={'left'} fontWeight={300} variant={isSmOrLess ? 'h5' : 'h4'} sx={{ fontFamily: 'Lora, serif'}}>LMHC, NCC</Typography>
                        <Typography align={'left'} fontWeight={400} variant={isSmOrLess ? 'subtitle2' : 'subtitle1'} sx={{ fontFamily: 'Lora, serif'}}>Licensed Mental Health Counselor</Typography>
                        <Typography align={'left'} variant={'body2'}>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container md={4}>
                    <Card component={Grid} elevation={2} minHeight={'300px'} width={"100%"} border={'1px solid #adb0a1'} sx={{ background: '#d8dbc8' }}></Card>
                </Grid>
            </Grid>
            <Grid item container width={'100%'} style={{ width: '100%' }} justifyContent={'center'} md={8}>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'left'} variant={'body2'}>
                    I'm here to support clients who are looking to strengthen their mental health, relationships, and adaptability. Every day we face challenges and stress; whether it is neurobiologically based, interpersonal, a trauma response, or the result of systemic stressors of an unjust society. While I use a variety of approaches and techniques based on client needs, my foundation is person centered. You deserve a safe, nonjudgmental space to break down or build back up. I believe my clients have all the answers within them and are capable of great things. My role is to use my education, training, and empathy to help you reach your goals.
                </Typography>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'left'} variant={'body2'}>
                    I am a licensed mental health counselor with 10 years of combined training & experience. I have worked with child & adult survivors of domestic and sexual violence at an advocacy agency, with families and adolescents diagnosed with substance use disorders in a residential treatment facility, and adults online/in person at an integrated healthcare setting.
                </Typography>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'left'} variant={'body2'}>
                    Reaching out to a counselor can be incredibly hard- but also worthwhile. I welcome anyone who is looking for support to email or call me to touch base and see if we are a good fit. We can also schedule a quick phone or video call if you would like. I also would love to hear from parents seeking therapy for their children.
                </Typography>
            </Grid>
            <Grid item container width={'100%'} style={{ width: '100%' }} justifyContent={'center'} md={4}>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'left'} variant={'body2'}>
                    
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Info;
